.protectPopup_wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 350px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  background: #f1f1f1;
  text-align: center;
  z-index: 99;
}

.protectPopup_wrapper .closeProtectPopupImg {
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  transform: rotate(0deg);
  transition: all .3s ease-in-out;
}

.protectPopup_wrapper .closeProtectPopupImg:hover {
  transform: rotate(360deg);
}

@media (max-width: 500px) {
  .protectPopup_wrapper {
    width: 90%;
    height: 400px;
  }
}
