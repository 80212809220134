.edit_wrapper {
  position: fixed;
  left: 0px;
  right: 0;
  bottom: 0px;
  z-index: 1;
  height: 200px;
  background: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_wrapper .edit_page_form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.edit_wrapper .edit_row {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.edit_wrapper .edit_row label {
  color: #fff;
  margin-bottom: 5px;
  font-size: 16px;
}

.edit_wrapper .edit_row input {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background: #fff;
  padding: 0 5px;
  font-size: 12px;
}

.edit_wrapper .edit_row textarea {
  resize: none;
  width: 200px;
  border-radius: 5px;
  height: 100px;
  padding: 5px;
  font-size: 12px;
}

.edit_wrapper button {
  border: none;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  background: #1d1d1d;
  cursor: pointer;
  margin-top: 22px;
}

.edit_wrapper .flags {
  width: 20px;
  margin-right: 5px;
}

.edit_slider {
  width: 200px;
  height: 145px;
  overflow-y: scroll;
  display: flex !important;
  flex-direction: column;
}

.edit_slider div {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  font-size: 10px !important;
  padding: 3px 0 !important;
  cursor: pointer;
}

.edit_slider div:hover {
  background: #3a80a7;
  color: #fff;
}

.editComponent_close img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.editComponent_close img:hover {
  transform: rotate(360deg);
}
