.edit_top_donators_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  background: #3a80a7;
}

.edit_top_donators_close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  cursor: pointer;
}

.edit_top_donators_holder {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.edit_top_donators_holder p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
}

.edit_top_donators_holder input {
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background: #fff;
  padding: 0 5px;
  font-size: 12px;
}

.edit_top_donators_holder textarea {
  resize: none;
  width: 250px;
  border-radius: 5px;
  height: 100px;
  padding: 5px;
  font-size: 12px;
}

.edit_top_donator_flags {
  overflow-y: scroll;
  height: 150px;
}

.edit_top_donator_flags > div {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
}

.edit_top_donator_flags > div > img {
  width: 30px;
  margin-right: 5px;
}

.edit_top_donator_flags .active {
  background: #000 !important;
}

.edit_top_donators_button {
  border: none;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  background: #1d1d1d;
  cursor: pointer;
}
