* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
html,
body,
#root,
.app {
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

ol,
ul {
  list-style: none;
}

button,
input,
select,
textarea {
  outline: none;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

.hidden {
  display: none !important;
}

@media (max-width: 1300px) {
  .container {
    width: 80%;
  }
}
