.termsPage {
  background: #f1f1f1;
  padding: 50px 0;
}

.termsPage h2 {
  text-align: center;
  padding-bottom: 50px;
  font-style: italic;
  font-size: 40px;
  font-family: "Playball", cursive;
  letter-spacing: 3px;
}

.termsPage_content {
  padding: 20px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.termsPage p {
  font-size: 14px;
  line-height: 2;
  margin-bottom: 30px;
}

@media (max-width: 750px) {
  .termsPage h2 {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .termsPage h2 {
    font-size: 26px;
  }
}

@media (max-width: 500px) {
  .termsPage .container {
    width: 90%;
  }
  .termsPage h2 {
    font-size: 23px;
    letter-spacing: 1px;
  }
  .termsPage p {
    font-size: 12px;
    line-height: 1.7;
  }
}

@media (max-width: 350px) {
    .termsPage {
        padding: 30px 0;
    }
    .termsPage h2 {
      font-size: 20px;
      padding-bottom: 30px;
    }
  }