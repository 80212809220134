.pagination_wrapper ul {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
}

.pagination_wrapper ul > li {
  width: 40px;
  height: 40px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
  color: #000;
}

.pagination_wrapper ul > li > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
}

.page_active {
  background: #3a80a7 !important;
  color: #fff !important;
}
