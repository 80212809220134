.topList_error_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.topList_error_wrapper .topList_error {
  background: #fff;
  width: 400px;
  height: 200px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.topList_error_wrapper .topList_error p {
  font-family: arial, sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
}

.topList_error_wrapper .topList_error button {
  width: 150px;
  height: 35px;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-family: arial, sans-serif;
  font-size: 18px;
  background: #63a715;
  cursor: pointer;
}

@media (max-width: 500px) {
  .topList_error_wrapper .topList_error {
    width: 90%;
    height: 150px;
  }

  .topList_error_wrapper .topList_error button {
    width: 50%;
    font-size: 14px;
  }
}
