.addUser_form {
  width: 100%;
  padding: 0 20px;
}

.addUser_form h2 {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
}

.addUser_form form {
  width: 100%;
}

.addUser_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.add_user_row {
  margin-bottom: 10px;
}

.add_user_row label {
  display: inline-block;
  width: 90px;
}

.add_user_row input {
  width: 460px;
  height: 35px;
  border: 0;
  color: #222;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 14px;
}

.addUser_mini_form {
  display: flex;
  width: 550px;
  margin-top: 20px;
}

.addUser_mini_form .add_user_row label {
  margin-bottom: 5px;
}

.addUser_mini_form > div {
  flex: 1;
  margin: 0 5px 0 0;
}

.addUser_mini_form > div:last-child {
  margin-right: 0;
}

.addUser_mini_form .add_user_row input {
  width: 100%;
}

.add_user_country textarea {
  resize: none;
  width: 400px;
  height: 120px;
  padding: 10px;
  border-radius: 5px;
  color: #222;
  font-size: 14px;
  border: 0;
}

.add_user_country .add_user_row {
  display: flex;
  flex-direction: column;
}

.add_user_country .add_user_row label {
  margin-bottom: 5px;
}

.add_user_country .country_list {
  width: 400px;
}

.addUser_btn_submit button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  background: #222;
  color: #fff;
  border: 0;
  font-weight: bold;
  font-size: 16px;
  margin-top: 50px;
  cursor: pointer;
}

.successCreateDonator {
  text-align: center;
  margin-top: 10px;
  color: red;
  font-size: 18px;
}
