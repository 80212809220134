.create_acc {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999;
}

.form_signUp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create_acc_content {
  position: relative;
  width: 100%;
  height: 100%;
}

.create_acc .container {
  height: 100%;
}

.create_acc h2 {
  font-size: 45px;
  padding: 30px 0;
  color: #556062;
  font-family: "Playball", cursive;
}

.create_acc_content::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  z-index: -1;
  background: url(../../img/gold.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.closeFormPopupImg {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
  width: 25px;
  transition: all 0.3s ease-in-out;
  transform: rotate(0);
}

.closeFormPopupImg:hover {
  transform: rotate(360deg);
}

.create_acc_close:hover {
  transform: rotate(360deg);
}

.create_acc_wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: calc(100% - 110px);
}

.form_box {
  width: 350px;
  height: 480px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
}

.create_acc_inputs .create_acc_row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.create_acc_inputs .create_acc_row label {
  font-size: 16px;
  color: #556062;
  margin-bottom: 10px;
}

.create_acc_inputs .create_acc_row input {
  width: 100%;
  height: 35px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 0 10px;
  color: #556062;
  font-size: 14px;
}

.create_acc_textareaFlags .create_acc_row {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.create_acc_textareaFlags .create_acc_row label {
  font-size: 16px;
  color: #556062;
  margin-bottom: 10px;
}

.create_acc_textareaFlags textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px;
  color: #556062;
  font-size: 12px;
  resize: none;
  letter-spacing: 1px;
}

.flags_list label {
  font-size: 16px;
  color: #556062;
}

.country_list {
  margin-top: 10px;
  height: 200px;
  overflow-x: hidden;
}

.country_list li {
  font-size: 16px;
  color: #556062;
  padding: 3px 0;
  cursor: pointer;
  outline: none;
}

.country_list img {
  width: 20px;
  margin-right: 10px;
}

.country_list li:hover {
  color: #000;
}

.create_acc_absolute {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create_acc_absolute button {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.7);
  cursor: pointer;
  color: #556062;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  width: 150px;
  height: 45px;
  border: none;
}

.create_acc_absolute button:hover {
  transform: scale(1.1);
}

.active {
  background: #d3b169;
  color: #fff !important;
}

.validation_p {
  color: rgb(163, 25, 25);
  margin-top: 5px;
  font-weight: bold;
  font-size: 14px;
}

/* Media query*/

@media (max-width: 1150px) {
  .create_acc_absolute {
    margin: 0 10px;
  }
  .create_acc .container {
    width: 95%;
  }
}

@media (max-width: 900px) {
  .create_acc_close {
    font-size: 28px;
    height: 35px;
  }
  .create_acc h2 {
    padding-bottom: 0;
  }
}

@media (max-width: 800px) {
  .create_acc {
    background: url(../../img/gold.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .create_acc_content::before {
    display: none;
  }
  .create_acc_content {
    overflow-y: scroll;
    padding-bottom: 30px;
  }
  .create_acc_wrap {
    flex-direction: column;
  }
  .create_acc .container {
    height: auto;
  }
  .create_acc h2 {
    font-size: 38px;
    margin-bottom: 20px;
  }
  .create_acc_absolute {
    order: 3;
    margin-top: 20px;
  }
  .form_box {
    margin: 10px 0;
  }
}

@media (max-width: 500px) {
  .create_acc_close {
    top: 5px;
  }
  .create_acc h2 {
    padding: 0;
    margin: 10px 0;
    font-size: 28px;
  }
  .form_box {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .form_box {
    width: 90%;
  }
  .closeFormPopupImg {
    top: 8px;
    right: 8px;
  }
}
