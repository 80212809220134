.homePage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.homePage_content_wrapper {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -44%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.homePage_label {
  margin-top: 30px;
  width: 500px;
  display: flex;
  position: relative;
}

.homePage_input {
  width: 100%;
  height: 45px;
  border: 2px solid #e1e1e1;
  border-radius: 30px;
  padding: 0 20px;
  padding-right: 30px;
  font-size: 16px;
  color: #5f6368;
}

.homePage_input:hover {
  border: 2px solid transparent;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

.homePage_input::placeholder {
  color: #63a715a4;
  letter-spacing: 1px;
  font-style: italic;
  font-size: 16px;
}

.homePage_btn {
  width: 60px;
  height: 100%;
  background: transparent;
  border: none;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
}

.homePage_btn span {
  font-weight: bold;
  color: #63a715a4;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.terms_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  margin-top: 10px;
}

.terms_list button {
  background: #f2f2f2;
  border: 1px solid transparent;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  color: #5f6368;
}

.terms_list button:hover {
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: linear-gradient(#f8f8f8, #f1f1f1);
  color: #000;
}

.terms_list button a {
  color: #5f6368;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.terms_list button a:hover {
  color: #000;
}

.language_select img {
  width: 20px;
  height: 15px;
}

/* Media quaries*/

@media (max-width: 650px) {
  .homePage_label {
    width: 80%;
  }
  .terms_list {
    width: 65%;
  }
  .logo {
    font-size: 48px;
  }
}

@media (max-width: 500px) {
  .logo {
    font-size: 44px;
  }
  .homePage_input {
    height: 43px;
    font-size: 14px;
  }
  .homePage_input::placeholder {
    font-size: 14px;
  }
  .homePage_btn span {
    font-size: 26px;
  }
}

@media (max-width: 450px) {
  .logo {
    font-size: 36px;
    margin-bottom: 15px;
  }
}

@media (max-width: 400px) {
  .homePage_label {
    width: 90%;
  }
  .terms_list {
    width: 75%;
  }
}

.mini_image {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 70px;
  cursor: pointer;
}

.mini_image img {
  width: 70px;
}

/* Language section */

.language_section {
  position: absolute;
  right: 40px;
  top: 30px;
  display: flex;
  align-items: center;
}

.language_section .language_title {
  font-size: 14px;
  color: #5f6368;
  margin-right: 10px;
  text-transform: capitalize;
}

.languagePopup p {
  font-size: 16px;
  color: #5f6368;
  cursor: pointer;
  text-transform: capitalize;
}

.language_select {
  position: absolute;
  top: 23px;
  right: -10px;
  background: #ccc;
  width: 40px;
  text-align: center;
}

.language_select_wrapper {
  position: relative;
  left: 0px;
  top: 5px;
}

.language_select_holder {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  z-index: 1;
}

.language_select.language_select_active {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #fff;
  background: #63a715;
  color: #fff;
  font-size: 15px;
  position: absolute;
  right: 0;
  top: -200px;
}

.language_select.language_select_active:hover {
  background: #98d651;
}

.language_select.language_select_active:nth-child(1) {
  top: 0;
}

.language_select.language_select_active:nth-child(2) {
  animation: slideDown2 0.2s forwards;
  animation-delay: 0.25s;
}

@keyframes slideDown2 {
  to {
    top: 40px;
    opacity: 1;
  }
  from {
    top: 0px;
  }
}

.language_select.language_select_active:nth-child(3) {
  animation: slideDown3 0.2s forwards;
  animation-delay: 0.5s;
}

@keyframes slideDown3 {
  to {
    top: 80px;
    opacity: 1;
  }
  from {
    top: 0px;
  }
}

.language_select.language_select_active:nth-child(4) {
  animation: slideDown4 0.2s forwards;
  animation-delay: 0.75s;
}

@keyframes slideDown4 {
  to {
    top: 120px;
    opacity: 1;
  }
  from {
    top: 0px;
  }
}

.language_select.language_select_active:nth-child(5) {
  animation: slideDown5 0.2s forwards;
  animation-delay: 1s;
}

@keyframes slideDown5 {
  to {
    top: 160px;
    opacity: 1;
  }
  from {
    top: 0px;
  }
}

/* NOTIFICATION WINNER */

.month_winner_wrapper_notifi {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.month_winner_notifi_holder {
  width: 350px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-left: 4px solid rgb(235, 38, 38);
  padding: 5px 20px 5px 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  opacity: 0;
  animation: showToUp1 2s forwards;
}

@keyframes showToUp1 {
  from {
    opacity: 0;
    transform: translateY(1000%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 1250px) {
  .month_winner_wrapper_notifi {
    top: 68px;
    transform: translate(0);
  }
}

@media (max-width: 780px) {
  .month_winner_wrapper_notifi {
    top: unset;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
  }
}

@media (max-width: 560px) {
  .month_winner_wrapper_notifi {
    left: 10px;
    transform: translateX(10px);
  }
}

@media (max-width: 480px) {
  .mini_image {
    bottom: 10px;
    right: 0px;
  }

  .mini_image img {
    width: 70%;
  }
  .month_winner_wrapper_notifi {
    bottom: 80px;
    left: 10px;
    right: 10px;
    transform: translate(0);
  }

  .month_winner_notifi_holder {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .month_winner_wrapper_notifi {
    bottom: 80px;
  }
}

/* Logo animation */

.logo_wrapper {
  transition: all 0.6s ease-in-out;
  border-bottom: 0px solid transparent;
}

.logo_wrapper h1 {
  font-size: 55px;
  font-family: "Playball", cursive;
  transition: all 0.5 ease-in-out !important;
  color: #63a715;
  animation: showToDown 3s forwards;
  cursor: pointer;
}

.logo_wrapper:hover {
  transform: scale(1.3) rotate(-10deg);
}

@keyframes showToDown {
  from {
    opacity: 0;
    transform: translateY(-1000%) scale(5);
  }
  to {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}

@media (max-width: 550px) {
  .logo_wrapper h1 {
    font-size: 35px;
  }
}

@media (max-width: 400px) {
  .logo_wrapper h1 {
    font-size: 30px;
  }
}
