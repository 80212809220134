.notifications_wrapper {
  position: fixed;
  top: 50%;
  left: 20px;
  z-index: 1;
  transform: translateY(-50%);
}

.notification_ul li {
  width: 350px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  margin: 20px 0;
  border-left: 4px solid purple;
  padding: 5px 20px 5px 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  position: relative;
}

.notification_li_img {
  width: 45px;
  height: 45px;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  transform: rotate(0);
  transition: all 0.5s ease-in-out;
}

.notification_li_img:hover {
  transform: rotate(360deg);
}

.notification_li_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 55px);
  height: 100%;
}

.notifi_title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.notifi_title img {
  width: 25px;
  margin-left: 5px;
}

.notifi_text {
  font-size: 13px;
  text-align: left;
}

.notifi_price {
  font-weight: bold;
  font-size: 14px;
}

.cancel_icon {
  width: 12px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  transition: all 0.5s ease-in-out;
  transform: rotate(0);
}

.cancel_icon:hover {
  transform: rotate(360deg);
}

@keyframes showToUp1 {
  from {
    opacity: 0;
    transform: translateY(1000%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.last_user_animation {
  opacity: 0;
  animation: showToUp1 2s forwards;
}

.animation {
  opacity: 0;
  animation: showToUp1 2s forwards;
}

.animation:nth-child(1) {
  animation-delay: 0.5s;
}

.animation:nth-child(2) {
  animation-delay: 1s;
}

.animation:nth-child(3) {
  animation-delay: 1.5s;
}

.animation:nth-child(4) {
  animation-delay: 2s;
}

.animation:nth-child(5) {
  animation-delay: 2.5s;
}

.notification_ul li:nth-child(1) {
  border-left-color: royalblue;
}

.notification_ul li:nth-child(2) {
  border-left-color: darkorange;
}

.notification_ul li:nth-child(3) {
  border-left-color: tomato;
}

.notification_ul li:nth-child(4) {
  border-left-color: yellowgreen;
}

.notification_ul li:nth-child(1) {
  border-left-color: royalblue;
}

@media (max-width: 780px) {
  .notifications_wrapper {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 560px) {
  .notifications_wrapper {
    top: 0px;
    left: 10px;
    transform: translate(10px, 0);
  }
}

@media (max-width: 480px) {
  .notifications_wrapper {
    left: 10px;
    right: 10px;
    transform: translate(0);
  }

  .notification_ul li {
    width: 100%;
  }
}
