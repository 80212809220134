.monthWinner_component {
  width: 100%;
  padding: 0 20px;
}

.monthWinner_component h2 {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
}

.monthWinner_component_item_holder {
  width: 100%;
  display: flex;
  align-items: center;
}

.month_winner_component_wrap_holder {
  width: 90%;
  margin-right: 20px;
  background: #fff;
}

.month_winner_table_header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.monthWinner_component_item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  height: 100px;
  font-size: 12px;
  text-align: center;
}

.monthWinner_component_item_content_title {
  display: flex;
  align-items: center;
}

.monthWinner_component_item_content_title img {
  width: 30px;
}

.monthWinner_img {
  width: 50px;
}

.monthWinner_component_item_button {
  width: 130px;
  height: 35px;
  background: #3a80a7;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.edit_top_donators_close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  transition: all 0.3s ease-in-out;
}

.edit_top_donators_close:hover {
  transform: rotate(360deg);
}
