.adminPanel_palypal {
  width: 100%;
}

.adminPanel_palypal h2 {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
}

.adminPanel_paypal_table {
  width: 100%;
  height: 100%;
}

.adminPanel_paypal_table_body {
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  align-items: center;
  justify-items: center;
  height: 70px;
  margin-bottom: 20px;
  background: #fff;
}

.adminPanel_paypal_table_body h2 {
  font-size: 18px;
}

.adminPanel_paypal_table_body p {
  font-size: 14px;
}

.adminPanel_paypal_table_body button {
  width: 80%;
  height: 35px;
  background: #3a80a7;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.paypalPopup {
  position: fixed;
  bottom: 0;
  left: 200px;
  right: 0;
  height: 200px;
  background: #555;
}

.paypalPopup_holder {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paypalPopup_close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: rotate(0);
}

.paypalPopup_close:hover {
  transform: rotate(360deg);
}

.paypalPopup_holder input {
  border: 0;
  width: 400px;
  height: 35px;
  padding: 0 10px;
  color: #222;
  border-radius: 5px;
}

.paypalPopup_holder button {
  width: 130px;
  height: 35px;
  background: #3a80a7;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
}
