.loginForm_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginForm_wrapper form {
  width: 500px;
  height: 300px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  padding: 20px 50px;
  background: url(../../img/gold.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.loginForm_wrapper .loginForm_row {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.loginForm_wrapper .loginForm_row label {
  font-size: 16px;
  margin-bottom: 10px;
}

.loginForm_wrapper .loginForm_row input {
  width: 100%;
  height: 30px;
  border-radius: 3px;
  border: none;
  padding: 0 10px;
  font-size: 14px;
}

.loginForm_wrapper .loginForm_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm_wrapper .loginForm_btn button {
  width: 150px;
  height: 35px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  background: #000;
}

.loginFailded {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: rgb(168, 2, 2);
  font-weight: bold;
}
