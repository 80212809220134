.evelope_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  min-height: 150px;
  z-index: 9;
  padding: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: #222;
}

.evelope_wrapper h3 {
  position: absolute;
  text-transform: capitalize;
  font-size: 17px;
  top: 5px;
  left: 25px;
  right: 25px;
  text-align: center;
  color: #fff;
  font-style: italic;
}

.evelope_holder {
  margin-top: 30px;
  padding: 5px;
  color: #f1f1f1;
  letter-spacing: 1px;
  font-size: 12px !important;
  text-align: left;
  font-family: arial, sans-serif;
  line-height: 1.5;
}

.evelope_wrapper span {
  display: flex;
  justify-content: flex-end;
}

.evelope_wrapper .closeEvelopePopup {
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
}

@media (max-width: 450px) {
  .evelope_wrapper {
    width: 90%;
  }
}
