.adminPanel_holder .adminPanel_holder_wrap .topDonators_wrapper {
  width: calc(100% - 200px) !important;
  padding: 30px;
  min-height: calc(100vh - 85px) !important;
}

.topDonators_wrapper_holder {
  width: 100%;
  height: 100%;
}

.topDonators_page_buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topDonators_page_buttons_wrapper button {
  width: 300px;
  height: 45px;
  border: 0;
  margin: 0 30px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background: #000;
}

.topDonators_page_button_active {
  background: #3a80a7 !important;
}

.topDonator_naslov {
  text-align: center;
  margin: 50px 0;
}

.topDonators_page_ul_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topDonators_page_ul_item_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
}

.topDonators_page_ul_item_content_text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: calc(100% - 150px);
  min-height: 70px;
  background: #fff;
  color: #fff;
  color: #222;
}

.topDonators_page_ul_item_content_text > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topDonators_page_ul_item_content_text_username {
  font-size: 16px;
}

.topDonators_page_ul_item_content_text_image_holder {
  width: 70px;
  height: 40px;
}

.topDonators_page_ul_item_content_text_image_holder img {
  width: 50px;
}

.topDonators_page_ul_item_content_text_number span {
  font-size: 20px;
  font-weight: bold;
}

.topDonators_page_ul_item_content_text_icon {
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
}

.edit_top_donator {
  width: 130px;
  height: 32px;
  border: 0;
  background: #3a80a7;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
