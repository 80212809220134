.topLists_wrapper {
  position: fixed;
  top: 0;
  left: 400px;
  right: 400px;
  bottom: 0;
  z-index: 999;
  background: #f5f5f5;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.topLists_wrap_holder {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px 20px 20px 20px;
}

.closeTopListPopupImg {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
}

.closeTopListPopupImg:hover {
  transform: rotate(360deg);
}

.topLists_buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topLists_buttons button {
  flex: 1;
  height: 40px;
  border: none;
  background: #444;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.topLists_buttons button:nth-child(2) {
  margin: 0 20px;
}

.topLists_buttons button.active_list_btn {
  background: #333;
}

.topList {
  width: 100%;
}

.topList h2 {
  text-align: center;
  margin: 10px 0;
  font-size: 24px;
  font-weight: bold;
}

.topList_row {
  display: grid;
  grid-template-columns: 120px 1fr 120px 120px 120px;
  justify-items: center;
  align-items: center;
  margin-bottom: 5px;
  height: 55px;
  border-radius: 5px;
  color: #fff;
}

.topList_row > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.topList_row > div:nth-child(odd) {
  background: #444;
}

.topList_row > div:nth-child(even) {
  background: #c2c2c2;
}

.topList_row .social_icon {
  font-weight: bold;
  font-size: 20px;
}

.topList_row .topList_row_content_username {
  font-size: 14px;
  padding: 0 5px;
}

.topList_row_content_donation {
  font-weight: bold;
  font-size: 16px;
}

.topList_row_content_message svg {
  font-size: 22px;
  cursor: pointer;
}

.topList_row .topList_country_img {
  width: 50px;
}

@media (max-width: 1500px) {
  .topList_row {
    grid-template-columns: 100px 1fr 100px 100px 100px;
  }
}

@media (max-width: 1350px) {
  .topList_row {
    grid-template-columns: 80px 1fr 80px 80px 80px;
  }

  .topList_row .topList_country_img {
    width: 40px;
  }
}

@media (max-width: 1300px) {
  .topLists_wrapper {
    left: 10%;
    right: 10%;
  }

  .topList_row {
    grid-template-columns: 120px 1fr 120px 120px 120px;
  }
}

@media (max-width: 800px) {
  .topLists_wrapper {
    left: 0;
    right: 0;
  }

  .topList_row {
    grid-template-columns: 120px 1fr 120px 120px 120px;
  }
}

@media (max-width: 650px) {
  .topList_row {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 500px) {
  .topLists_buttons button {
    font-size: 14px;
  }

  .topList_row {
    grid-template-columns: 50px 1fr 50px 50px 50px;
    height: 45px;
  }

  .topList_row .topList_country_img {
    width: 30px;
  }

  .topList_row .social_icon {
    font-size: 16px;
  }

  .topList_row_content_donation {
    font-size: 14px;
  }

  .topList_row_content_message svg {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .topList h2 {
    font-size: 18px;
  }

  .topLists_buttons button {
    font-size: 12px;
  }

  .topLists_buttons button:nth-child(2) {
    margin: 0 5px;
  }
}

@media (max-width: 350px) {
  .topList_row {
    grid-template-columns: 45px 1fr 45px 45px 45px;
    height: 45px;
  }

  .topList_row .topList_country_img {
    width: 25px;
  }

  .topList_ul {
    overflow-y: scroll;
    height: 395px;
  }
}
