.adminPanel_wrapper {
  background: #ecf0f5;
}

.adminPanel_header {
  background: #3a80a7;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminPanel_header_holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminPanel_logo {
  font-family: "Playball", cursive;
  font-size: 30px;
  color: #fff;
}

.adminPanel_logout button {
  border: none;
  background: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.adminPanel_holder {
  background: #ecf0f5;
}

.adminPanel_holder_wrap {
  display: flex;
  min-height: calc(100vh - 60px);
}

.adminPanel_menu {
  width: 200px;
  background: #222d32;
}

.adminPanel_menu ul li a {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
}

.adminPanel_menu ul li a svg {
  margin-right: 10px;
}

.adminPanel_menu ul li:hover {
  background: #1d2427;
}

.adminPanel_content {
  width: calc(100% - 200px);
  padding: 0 20px;
}

/* Table */

.adminPanel_content_table {
  width: 100%;
  padding-bottom: 20px;
}

.adminPanel_content_table_header ul {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  align-items: center;
  height: 40px;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 3px;
  background: #fff;
}

.adminPanel_content_table_header ul li {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #464646;
  font-weight: bold;
  font-size: 15px;
  border-right: 1px solid #ccc;
}

.adminPanel_content_table_header ul li:last-child {
  border-right: none;
}

.adminPanel_content_table_body ul {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 3px;
  background: #fff;
  position: relative;
}

.adminPanel_content_table_body ul:first-child {
  border-top: 1px solid #ccc;
}

.adminPanel_content_table_body ul li {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-right: 1px solid #ccc;
  color: #464646;
  padding: 0 5px;
  word-break: break-all;
}

.adminPanel_content_table_body ul li:last-child {
  border-right: none;
}

.adminPanel_content_table_body ul li img {
  width: 40px;
}

.adminPanel_content_table_body ul li:nth-child(1) {
  font-size: 14px;
}

.adminPanel_content_table_body ul li:nth-child(2) {
  font-size: 14px;
}

.adminPanel_content_table_body ul li:nth-child(3) {
  font-size: 12px;
}

.adminPanel_content_table_body ul li:nth-child(4) {
  font-size: 10px;
  overflow: hidden;
}

.adminPanel_content_table_body ul li:nth-child(6) {
  font-size: 16px;
  font-weight: bold;
}

.adminPanel_content_table_body ul li:nth-child(7) {
  font-size: 12px;
  line-height: 1.5;
}

.adminPanel_content_table_body ul li:nth-child(8) button {
  border: none;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background: #3a80a7;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
}

.adminPanel_content_table_body ul li:nth-child(8) button:hover {
  background: #218fca;
}

.adminPanel_content_table_body ul li:nth-child(9) button {
  border: none;
  width: 70%;
  height: 30px;
  color: #fff;
  border-radius: 5px;
  background: rgb(192, 29, 29);
  font-size: 14px;
  cursor: pointer;
}

.adminPanel_content_table_body ul li:nth-child(9) button:hover {
  background: rgb(243, 22, 22);
}

#time {
  margin-top: 10px;
}

.adminPanel_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

/* .adminPanel_search input {
  width: 300px;
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  color: #464646;
  font-size: 14px;
} */

/* .adminPanel_search input::placeholder {
  color: #464646;
  font-size: 14px;
} */

.adminPanel_search span {
  font-weight: bold;
  font-size: 18px;
}

